import { Injectable, OnInit } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';

import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AuthService } from '../../services/auth.service';
import { Urls } from '../urls';

@Injectable()
export class TokenInterceptor implements HttpInterceptor, OnInit {
  constructor(private authService: AuthService) {}

  ngOnInit(): void {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!request.url.includes('https://s3.ap-southeast-2.amazonaws.com')) {
      request = request.clone({
        setHeaders: {
          Authorization: `${localStorage.getItem('token')}`,
        },
      });
    }
    return next.handle(request).pipe(
      tap(
        () => {},
        (err: any) => {
          if (err instanceof HttpErrorResponse) {
            if (err.status === 401) {
              this.authService
                .logout()
                .then(() => {
                  if (request.url !== Urls.login) {
                    localStorage.clear();
                    location.href = '';
                  }
                })
                .catch((error) => {
                  if (request.url !== Urls.login) {
                    localStorage.clear();
                    location.href = '';
                  }
                });
            }
          }
        }
      )
    );
  }
}
