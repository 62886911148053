import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'multi-select',
  templateUrl: './multi-select.component.html',
  styleUrls: ['./multi-select.component.scss'],
})
export class MultiSelectComponent implements OnInit, OnChanges {
  @Input() list;
  @Input() isTable;
  @Output() selectionChange = new EventEmitter();
  @Output() selectedValuesChange = new EventEmitter();
  displayDataList = [];
  @Input() disabled = false;
  @Input() selectedValues;
  @Input() selectedSection;
  constructor() {}

  ngOnInit(): void {}

  onSelectionChange(event) {
    this.displayDataList = [];
    event.forEach((e) => {
      this.displayDataList.push(this.list.find((lData) => lData._id === e));
    });
    this.selectionChange.emit(this.selectedValues);
  }

  removeSelection(index, event) {
    event.stopPropagation();
    event.preventDefault();
    this.selectedValues.splice(index, 1);
    this.selectedValues = JSON.parse(JSON.stringify(this.selectedValues));
    this.displayDataList.splice(index, 1);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.list && changes.list.currentValue && changes.selectedValues && changes.selectedValues.currentValue) {
      changes.list.currentValue.forEach((list) => {
        if (changes.selectedValues.currentValue.includes(list._id)) {
          this.displayDataList.push({ _id: list._id, name: list.name, capacity: list.capacity });
        }
      });
    }
    if (
      changes.selectedSection &&
      Object.prototype.hasOwnProperty.call(changes.selectedSection, 'firstChange') &&
      !changes.selectedSection.firstChange
    ) {
      this.displayDataList = [];
      this.selectedValues = [];
    }
  }
}
