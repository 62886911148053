import { Component, Input, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { DateTimezoneHelper } from '../../utils/helper/date-timezone.helper';

@Component({
  selector: 'app-week-time-picker',
  templateUrl: './week-time-picker.component.html',
  styleUrls: ['./week-time-picker.component.scss'],
})
export class WeekTimePickerComponent implements OnInit {
  @Input() timeSchedule;
  getTime: Date;
  constructor(private authService: AuthService) {
    this.getTime = new Date();
  }
  ngOnInit(): void {
    this.timeSchedule.forEach((ele) => {
      if (!ele.isEnable) {
        ele.closeTime = null;
        ele.lastBookingTime = null;
        ele.openTime = null;
        // new DateTimezoneHelper().convertUTCtoTimeZone(moment(ele.closeTime)
        // ele.closeTime =  ele.closeTime;
        // ele.lastBookingTime = ele.lastBookingTime);
        // ele.openTime = new DateTimezoneHelper().convertUTCtoTimeZone( ele.openTime, this.authService.getTimeZone());
      }
    });
  }

  getChecked(event, index): void {
    this.timeSchedule.forEach((ele) => {
      if (event === true && ele.closeTime && ele.lastBookingTime && ele.openTime) {
        this.timeSchedule[index].closeTime = ele.closeTime;
        this.timeSchedule[index].lastBookingTime = ele.lastBookingTime;
        this.timeSchedule[index].openTime = ele.openTime;

        return;
      } else if (event === false) {
        this.timeSchedule[index].closeTime = undefined;
        this.timeSchedule[index].lastBookingTime = undefined;
        this.timeSchedule[index].openTime = undefined;
        this.timeSchedule[index] = JSON.parse(JSON.stringify(this.timeSchedule[index]));
      }
    });
  }

  checkDateForChanges(e, i, type): void {
    const year = this.getTime.getFullYear();
    const month = this.getTime.getMonth();
    const day = this.getTime.getDate();
    if (this.timeSchedule[i].closeTime && this.timeSchedule[i].openTime && this.timeSchedule[i].lastBookingTime) {
      this.timeSchedule[i].closeTime.setFullYear(year);
      this.timeSchedule[i].closeTime.setMonth(month);
      this.timeSchedule[i].closeTime.setDate(day);
      this.timeSchedule[i].openTime.setFullYear(year);
      this.timeSchedule[i].openTime.setMonth(month);
      this.timeSchedule[i].openTime.setDate(day);
      this.timeSchedule[i].lastBookingTime.setFullYear(year);
      this.timeSchedule[i].lastBookingTime.setMonth(month);
      this.timeSchedule[i].lastBookingTime.setDate(day);
    }
  }
}
