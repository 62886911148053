<nb-form-field class="mt-5 phone-input">
  <input
    nbInput
    fullWidth
    placeholder="Enter phone"
    [(ngModel)]="phone"
    [disabled]="disabled"
    (keypress)="restrictNumber($event)"
    fieldSize="large"
    (blur)="onNumberChanged($event)"
  />
  <nb-select
    nbPrefix
    placeholder="code"
    size="large"
    optionsListClass="custom-option-list"
    [(selected)]="selectedCode"
    [disabled]="disabled"
    (selectedChange)="onCodeChange($event)"
  >
    <nb-select-label>
      <div class="d-flex align-center phone-select-label">
        <img height="18px" width="22px" [src]="selectedCode?.flag" />
        <span class="ml-5 font-regular">{{ selectedCode?.callingCodes }}</span>
      </div>
    </nb-select-label>
    <nb-option *ngFor="let code of codeList" [value]="code" [disabled]="disabled">
      <div class="d-flex align-center just-between w-100">
        <div class="d-flex align-center">
          <img height="20px" width="28px" [src]="code.flag" />
          <div class="ml-10">{{ code.name }}</div>
        </div>
        <div class="ml-10">{{ code.callingCodes }}</div>
      </div>
    </nb-option>
  </nb-select>
</nb-form-field>
