import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'accordian-header',
  templateUrl: './accordian-header.component.html',
  styleUrls: ['./accordian-header.component.scss'],
})
export class AccordianHeaderComponent implements OnInit {
  @Input() isExpanded = false;
  @Input() title;
  @Input() isNext;
  @Input() isCancel;
  @Input() isSave;
  @Output() closeEventEmitter = new EventEmitter();
  @Output() saveEventEmitter = new EventEmitter();
  @Output() nextEventEmitter = new EventEmitter();
  constructor() {}

  ngOnInit(): void {}
  onClose() {
    this.closeEventEmitter.emit();
  }
  onSave() {
    this.saveEventEmitter.emit();
  }
  onNext() {
    this.nextEventEmitter.emit();
  }
}
