<div class="closeNav" (click)="closeNav()"  *ngIf="width !== '0px'"></div>
<div class="side-nav-main-container" [ngStyle]="{ width: width }">
  <div class="side-nav-inner" *ngIf="width !== '0px'">
    <div class="side-nav-header">
      <div>
        {{ options.title }}
      </div>
      <div class="d-flex">
        <div *ngIf="isShowWalkin">
          <nb-button-group class="ml-6" filled status="primary">
            <button
              (pressedChange)="onBookingTypeChange($event, 'walkin')"
              [pressed]="bookingType === 'walkin'"
              class="icon-button"
              nbButtonToggle
              nbTooltip="New Walk-in"
              nbTooltipPlacement="bottom"
              status="warning"
            >
              <span class="material-icons-round"> directions_walk </span>
            </button>
            <button
              (pressedChange)="onBookingTypeChange($event, 'new-booking')"
              [pressed]="bookingType === 'phone'"
              class="icon-button"
              nbButtonToggle
              nbTooltip="New Booking"
              nbTooltipPlacement="bottom"
              status="warning"
            >
              <nb-icon class="right-menu-icon" icon="plus-circle-outline"></nb-icon>
            </button>
          </nb-button-group>
        </div>
        <div *ngIf="isShowButtonsIcons">
          <nb-button-group class="ml-6" filled status="primary">
            <button
              class="icon-button"
              nbButtonToggle
              [pressed]="selectedType === 'phone'"
              nbTooltip="Booking on phone"
              nbTooltipPlacement="bottom"
              (pressedChange)="onTypeChange($event, 'phone')"
            >
              <nb-icon class="right-menu-icon" icon="phone-call-outline"></nb-icon>
            </button>
            <button
              class="icon-button"
              nbButtonToggle
              [pressed]="selectedType === 'email'"
              nbTooltip="Booking on message"
              nbTooltipPlacement="bottom"
              (pressedChange)="onTypeChange($event, 'email')"
            >
              <nb-icon class="right-menu-icon" icon="email-outline"></nb-icon>
            </button>
          </nb-button-group>
        </div>
        <div>
          <button class="ml-10 border-none" nbButton status="basic" nbTooltip="Close" (click)="closeNav()">
            <nb-icon icon="close-circle-outline" [options]="{ color: '#66737F' }"></nb-icon>
          </button>
        </div>
      </div>
    </div>
    <div class="side-nav-content">
      <ng-template [ngTemplateOutlet]="options.templateRef"></ng-template>
    </div>
  </div>
</div>
