import { Component, OnInit } from '@angular/core';
import { NotificationService, NotificationStatus } from '../../services/notification.service';
import { AuthService } from '../../services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-permission-denied',
  templateUrl: './permission-denied.component.html',
  styleUrls: ['./permission-denied.component.scss'],
})
export class PermissionDeniedComponent implements OnInit {
  constructor(
    private _authService: AuthService,
    private _notificationService: NotificationService,
    private router: Router,
    private activeRoute: ActivatedRoute
  ) {}
  ngOnInit(): void {}
  onLogout() {
    this._authService
      .logout()
      .then((res) => {
        this.router.navigate(['login']);
      })
      .catch((err) => {
        console.log(err);
        this._notificationService.showNotification(NotificationStatus.ERROR, err.message ? err.message : 'Something went wrong');
      });
  }
}
