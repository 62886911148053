import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { NotificationService, NotificationStatus } from '../../services/notification.service';

@Component({
  selector: 'pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
})
export class PaginationComponent implements OnInit, OnChanges {
  @Output() changePagination = new EventEmitter<any>();
  @Input() pagination;
  @Input() paginationConfig;
  recordStart;
  recordEnd;
  constructor(private _notificationService: NotificationService) {}

  ngOnInit(): void {
    this.setConfig(this.paginationConfig);
  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes.paginationConfig && changes.paginationConfig.previousValue) {
      this.setConfig(this.paginationConfig);
    }
  }
  changePage(no) {
    if (this.pagination.currentPage !== no) {
      this.pagination.currentPage = no;
      this.showPaginationDetail();
      this.changePagination.emit({ type: 'page', value: no });
    }
  }
  /*Function for going to next page*/
  nextPage() {
    if (!this.pagination.disableNext) {
      this.changePage(this.pagination.currentPage + 1);
    }
  }

  /*
   Function for going to prev page
*/
  prevPage() {
    if (!this.pagination.disablePrev) {
      this.changePage(this.pagination.currentPage - 1);
    }
  }
  /*Function for going to last page*/
  lastPage() {
    if (!this.pagination.disableNext) {
      this.changePage(this.pagination.totalpage);
    }
  }
  firstPage() {
    if (!this.pagination.disablePrev) {
      this.changePage(1);
    }
  }
  setConfig(result) {
    this.pagination.currentPage = result.page;
    this.pagination.totalpage = result.pages;
    /*  if (result.page > result.pages) {
        this.changePage(1);
      }*/
    this.pagination.disablePrev = this.pagination.currentPage === 1;
    this.pagination.disableNext = this.pagination.currentPage === this.pagination.totalpage;

    let count = 0;
    this.pagination.totalPages = [];
    if (this.pagination.totalpage > 5) {
      // @ts-ignore
      for (
        let i =
          this.pagination.currentPage + 5 <= this.pagination.totalpage
            ? this.pagination.currentPage === 1
              ? this.pagination.currentPage
              : this.pagination.currentPage
            : this.pagination.totalpage - 4;
        i <= (this.pagination.currentPage + 5 <= result.pages ? this.pagination.currentPage + 5 : this.pagination.totalpage);
        i++
      ) {
        count++;
        if (count > 5 || i > this.pagination.totalpage) {
          break;
        }
        this.pagination.totalPages.push({
          no: i,
          status: i === result['page'],
        });
      }
    } else {
      for (let i = 1; i <= result['pages']; i++) {
        this.pagination.totalPages.push({
          no: i,
          status: i === result['page'],
        });
      }
    }
    this.showPaginationDetail();
  }
  showPaginationDetail() {
    let pageTotal =
      this.paginationConfig.page * this.paginationConfig.limit < this.paginationConfig.total
        ? this.paginationConfig.page * this.paginationConfig.limit
        : this.paginationConfig.total;
    this.recordStart = this.paginationConfig.page * this.paginationConfig.limit - this.paginationConfig.limit + 1;
    this.recordEnd = pageTotal;
  }
  changeLimit(event: any) {
    this.changePagination.emit({ type: 'limit', value: event });
  }

  pageNumberChange(event: any) {
    if (event.target.value !== '0' && event.target.value <= this.pagination.totalpage) {
      this.changePagination.emit({ type: 'page', value: event.target.value });
    } else {
      this._notificationService.showNotification(NotificationStatus.ERROR, 'Requested page is not available.');
    }
  }
  restrictNumber(event) {
    const pattern = /[0-9\+\-\ ]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (!pattern.test(inputChar)) {
      event.preventDefault();
    }
  }
}
