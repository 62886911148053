import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
})
export class SearchComponent implements OnInit {
  @Input() search: string;
  @Input() placeholder: string;
  @Output() searchList = new EventEmitter<any>();
  constructor() {}

  ngOnInit(): void {}
  doSearch(event) {
    this.searchList.emit(event);
  }
}
