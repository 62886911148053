import { NgModule } from '@angular/core';

import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './components/login/login.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { SetResetPasswordComponent } from './components/set-reset-password/set-reset-password.component';
import { AuthGuard } from './utils/guard/auth.guard';
import { VenueListComponent } from './components/venue-list/venue-list.component';
import { AdminPermissionGuard } from './utils/guard/adminPermission.guard';
import { PermissionDeniedComponent } from './components/permissionDenied/permission-denied.component';
const routes: Routes = [
  {
    path: 'admin',
    loadChildren: () => import('./admin/admin.module').then((m) => m.AdminModule),
    canActivate: [AuthGuard, AdminPermissionGuard],
  },
  {
    path: 'client',
    loadChildren: () => import('./client/client.module').then((m) => m.ClientModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'permission',
    component: PermissionDeniedComponent,
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'forgot',
    component: ForgotPasswordComponent,
  },
  {
    path: 'reset/:id',
    component: SetResetPasswordComponent,
  },
  {
    path: 'set/:id',
    component: SetResetPasswordComponent,
  },
  {
    path: 'venuelist',
    component: VenueListComponent,
    canActivate: [AuthGuard],
  },
  {
    path: '**',
    redirectTo: 'client',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
