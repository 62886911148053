<div>
  <form [formGroup]="form">
    <div class="d-grid col-width-3 col-gap-16 common-form">
      <div [ngClass]="question.elementClass" *ngFor="let question of questions">
        <dynamic-form-questions [questionList]="questions" [question]="question" [form]="form"></dynamic-form-questions>
      </div>
    </div>

    <div class="mt-20 text-right">
      <button (click)="onClick(button)" *ngFor="let button of buttons" nbButton status="primary">{{ button.label }}</button>
    </div>
  </form>
</div>
