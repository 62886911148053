import { Component, Input, OnInit, Output, EventEmitter, OnChanges, SimpleChanges, OnDestroy, ComponentRef } from '@angular/core';
import { FormBase, FormControlTypes } from '../../utils/helper/FormBase';
import { FormGroup } from '@angular/forms';
import { FormControlService } from '../../services/form-control.service';
import { NotificationService, NotificationStatus } from '../../services/notification.service';
import { FormValidations } from '../../utils/helper/form-validations';

@Component({
  selector: 'dynamic-form',
  templateUrl: './dynamic-form.component.html',
  styleUrls: ['./dynamic-form.component.scss'],
})
export class DynamicFormComponent implements OnInit, OnChanges {
  @Input() questions: FormBase[] = [];
  @Input() buttons: [] = [];
  @Input() isResetForm = false;
  @Output() saveFormEmitter = new EventEmitter();
  @Output() cancelFormEmitter = new EventEmitter();
  form: FormGroup;
  payLoad = '';
  constructor(private _formControlService: FormControlService, private _notificationService: NotificationService) {}

  ngOnInit(): void {}
  onSubmit() {
    this.questions.forEach((question) => {
      if (question.type === 'country') {
        if (this.form.controls[question.key].value.name) {
          this.form.controls[question.key].setValue(this.form.controls[question.key].value.name);
        }
      }
    });
    if (this.form.valid) {
      let error = new FormValidations().checkValidations(this.form, this.questions);
      if (error && error.errStatus) {
        this._notificationService.showNotification(NotificationStatus.ERROR, error.error);
        return;
      }
      this.questions.forEach((question) => {
        if (question.controlType === FormControlTypes.PHONE) {
          this.form.controls[question.key].setValue(question.code.callingCodes + '-' + this.form.controls[question.key].value);
        }
      });
      this.saveFormEmitter.emit(this.form.getRawValue());
      this.payLoad = JSON.stringify(this.form.getRawValue());
    } else {
      let error = new FormValidations().checkInvalidForm(this.form, this.questions);
      this._notificationService.showNotification(NotificationStatus.ERROR, error);
    }
  }

  onClick(button) {
    switch (button.type) {
      case 'submit':
        this.onSubmit();
        break;
      case 'cancel':
        this.cancelFormEmitter.emit();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.isResetForm && changes.isResetForm.currentValue) {
      this.form.reset();
    }
    if (changes.questions && changes.questions.currentValue.length) {
      this.form = this._formControlService.toFormGroup(this.questions);
    }
  }
}
