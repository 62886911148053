<div class="common-list">
  <div class="list-header" [ngStyle]="{ 'margin-left': listStructure.isToggle ? '80px' : '30px' }">
    <div *ngFor="let column of listStructure.columns" [style.width]="column.width + '%'">
      {{ column.name }}
    </div>
    <div class="list-action" *ngIf="listStructure.actions.length > 0">Action</div>
  </div>
  <nb-list
    cdkDropList
    (cdkDropListDropped)="onDrop($event)"
    [ngClass]="listClass"
    infiniteScroll
    [scrollWindow]="false"
    [infiniteScrollDistance]="1"
    [infiniteScrollUpDistance]="2"
    [infiniteScrollThrottle]="50"
    [fromRoot]="true"
    (scrolled)="onScrollDown()"
  >
    <nb-list-item *ngFor="let row of listData; let rIndex = index" cdkDrag>
      <div class="list-icon" *ngIf="isDrag" cdkDragHandle>
        <nb-icon icon="more-vertical-outline" [options]="{ color: '#B8C1CC' }"></nb-icon>
        <nb-icon icon="more-vertical-outline" [options]="{ color: '#B8C1CC' }"></nb-icon>
      </div>
      <ng-container *ngIf="listStructure.isToggle">
        <nb-toggle [disabled]="!row.isShowAction" [(ngModel)]="row['isActive']" (ngModelChange)="selectSingle($event, rIndex)"></nb-toggle>
      </ng-container>
      <div
        class="list-title"
        [ngClass]="{ 'ml-10': listStructure.isToggle }"
        *ngFor="let cell of listStructure.columns"
        [style.width]="cell.width + '%'"
      >
        <div *ngIf="cell.type === 'textWithIcon'" class="d-flex align-center">
          <div class="color-box">
            <span class="material-icons-round" [ngStyle]="{ color: row['color'] }">{{ row[cell.icon] }} </span>
          </div>
          <p class="ml-10 list-text">{{ row[cell.value] | titlecase }}</p>
        </div>
        <div *ngIf="cell.type === 'textWithColor'" class="d-flex align-center">
          <div class="color-box" [ngStyle]="{ backgroundColor: row[cell.otherValue] }">
            <span class="material-icons-round" style="color: white"> {{ row[cell.icon] }} </span>
          </div>
          <p class="ml-10 list-text">{{ row[cell.value] | titlecase }}</p>
        </div>
        <div *ngIf="cell.type === 'text'">
          <p class="list-text">{{ row[cell.value] | titlecase }}</p>
        </div>
      </div>
      <div class="list-action" [ngClass]="{ 'hide-action': !row['isShowAction'] }">
        <nb-icon
          class="cursor-pointer"
          *ngFor="let action of listStructure.actions"
          [icon]="action.icon"
          (click)="onActionClick(action.type, rIndex, row._id)"
          [options]="{ color: '#66737F', height: '20px', width: '20px' }"
        ></nb-icon>
      </div>
    </nb-list-item>
  </nb-list>
</div>
