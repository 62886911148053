import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { VenueService } from '../../services/venue.service';

@Injectable()
export class AdminPermissionGuard implements CanActivate {
  constructor(private _authService: AuthService, private router: Router, private _venueService: VenueService) {}
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (this._authService.getToken()) {
      if(this._authService.getAccessLevel() === 'admin' || this._authService.getAccessLevel() === 'superadmin'){
        return true;
      }
      else{
        this.router.navigate(['permission'], {
          queryParams: { returnUrl: state.url },
        });
      }
    } else {
      this.router.navigate(['login'], {
        queryParams: { returnUrl: state.url },
      });
    }
    return false;
  }
}
