<section class="common-forms">
  <div class="row">
    <!-- input -->
    <div class="common-frm" [ngClass]="class" *ngIf="type === 'input'">
      <label *ngIf="labelFalse">Service Name</label>
      <input type="text" nbInput fullWidth fieldSize="large" placeholder="Enter Section Name" />
    </div>
    <!-- select -->
    <div class="common-frm" [ngClass]="class" *ngIf="type === 'input'">
      <label *ngIf="labelFalse">Service Name</label>
      <input type="text" nbInput fullWidth fieldSize="large" placeholder="Enter Section Name" />
    </div>
  </div>
</section>
