import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Urls } from '../utils/urls';
import { Subject } from 'rxjs';

@Injectable()
export class VenueService {
  resDetailsSaveChanged = new Subject();
  paymentDetailsSaveChanged = new Subject();
  permissions;
  constructor(private http: HttpClient) {}

  getVenue(page, limit) {
    return new Promise((resolve, reject) => {
      this.http.get(Urls.venues + '?page=' + page + '&limit=' + limit).subscribe(
        (res) => {
          resolve(res);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }
  getAllVenue() {
    return new Promise((resolve, reject) => {
      this.http.get(Urls.venues + '?all=true').subscribe(
        (res) => {
          resolve(res);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }
  addVenue(data) {
    return new Promise((resolve, reject) => {
      this.http.post(Urls.venues, data).subscribe(
        (res) => {
          resolve(res);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }
  updateVenue(id, data) {
    return new Promise((resolve, reject) => {
      this.http.put(Urls.venues + '/' + id, data).subscribe(
        (res) => {
          resolve(res);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }
  venueDetailById(id) {
    return new Promise((resolve, reject) => {
      this.http.get(Urls.venues + '/' + id).subscribe(
        (res) => {
          resolve(res);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }
  deleteVenue(id) {
    return new Promise((resolve, reject) => {
      this.http.delete(Urls.venues + '/' + id).subscribe(
        (res) => {
          resolve(res);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }
  getRoleDetails(venueId): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.get(Urls.roles + '/' + venueId + '/get-user-role-for-venue').subscribe(
        (res) => {
          resolve(res);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  setPermission(permissions) {
    sessionStorage.setItem('permissions', permissions);
  }
  getPermission() {
    return sessionStorage.getItem('permissions');
  }
}
