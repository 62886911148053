import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'common-table-list',
  templateUrl: './common-table-list.component.html',
  styleUrls: ['./common-table-list.component.scss'],
})
export class CommonTableListComponent implements OnInit {
  @Input() listData;
  @Input() listStructure;
  @Input() listClass;
  @Output() actionEmitter = new EventEmitter();
  @Output() scrollEmitter = new EventEmitter();
  @Output() onDropElement = new EventEmitter();
  @Input() isDrag = false;
  constructor() {}

  ngOnInit(): void {}

  onDrop(event) {
    this.onDropElement.emit(event);
  }

  onActionClick(type, index: number, _id: any) {
    this.actionEmitter.emit({ type: type, index: index, _id });
  }

  onScrollDown() {
    this.scrollEmitter.emit();
  }

  selectSingle(event: any, index: number) {
    this.actionEmitter.emit({ type: 'activeChange', index: index, value: event });
  }
}
