import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-common-form',
  templateUrl: './common-form.component.html',
  styleUrls: ['./common-form.component.scss'],
})
export class CommonFormComponent implements OnInit {
  class = 'col-6';
  labelFalse = true;
  type = 'input';
  constructor() {}

  ngOnInit(): void {}
}
