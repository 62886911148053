<nb-layout>
  <nb-layout-column>
    <div class="h-100 w-100 login-main-container">
      <div class="login-header">
        <img src="assets/images/login-header.svg" />
      </div>
      <div class="login-container">
        <div class="login-inner-container">
          <div class="login-logo">
            <img src="../../../assets/images/logo-white.svg" />
          </div>
          <!--Login Form-->
          <div class="main-form" [formGroup]="loginForm" *ngIf="status === 'login'">
            <label class="login-form-label">Email</label>
            <nb-form-field class="mt-10 custom-input">
              <nb-icon nbPrefix icon="email-outline"></nb-icon>
              <input
                type="email"
                nbInput
                fullWidth
                placeholder="Enter Email"
                formControlName="email"
                [status]="loginForm.controls['email'].touched && loginForm.controls['email'].invalid ? 'danger' : 'basic'"
              />
            </nb-form-field>
            <div class="mt-10">
              <label class="login-form-label">Password</label>
              <nb-form-field class="mt-10 custom-input">
                <nb-icon nbPrefix icon="lock-outline"></nb-icon>
                <input
                  #loginPass
                  type="password"
                  nbInput
                  fullWidth
                  placeholder="Enter Password"
                  formControlName="password"
                  (keyup.enter)="onLogin()"
                  [status]="loginForm.controls['password'].touched && loginForm.controls['password'].invalid ? 'danger' : 'basic'"
                />
                <button class="password-icon" nbSuffix nbButton ghost (click)="toggleShowPassword('login', loginPass)">
                  <nb-icon
                    [icon]="showPassword ? 'eye-outline' : 'eye-off-2-outline'"
                    [attr.aria-label]="showPassword ? 'hide password' : 'show password'"
                  >
                  </nb-icon>
                </button>
              </nb-form-field>
            </div>
            <div class="d-flex just-end">
              <p class="forgot-password" (click)="goToForgotPassword()">Forgot Password?</p>
            </div>
            <div class="text-center">
              <button class="primary-button" status="primary" nbButton (click)="onLogin()" [nbSpinner]="loading" nbSpinnerStatus="primary">
                Login
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="login-footer w-100">
        <div>&copy; 2021 <span class="font-bold">BookYa</span> - All Rights Reserved</div>
      </div>
    </div>
  </nb-layout-column>
</nb-layout>
