import { environment } from '../../environments/environment';

export class Urls {
  static baseUrl = environment.apiUrl;
  static auth = Urls.baseUrl + 'auth';
  static login = Urls.baseUrl + 'auth/login';
  static logout = Urls.baseUrl + 'auth/logout';
  static forgotPassword = Urls.baseUrl + 'auth/forgot-password';
  static venues = Urls.baseUrl + 'venues';
  static fileUpload = Urls.baseUrl + 'images/upload';
  static images = Urls.baseUrl + 'images';
  static roles = Urls.baseUrl + 'roles';
  static users = Urls.baseUrl + 'users';
  static event = Urls.baseUrl + 'event';
  static services = Urls.baseUrl + 'services';
  static blockouts = Urls.baseUrl + 'blockouts';
  static floorPlan = Urls.baseUrl + 'floor-plan';
  static section = Urls.baseUrl + 'section';
  static bookingsettings = Urls.baseUrl + 'email';
  static tableJoin = Urls.baseUrl + 'table-joining-rules';
  static bookingOptions = Urls.baseUrl + 'booking-options';
  static bookingStatus = Urls.baseUrl + 'booking-statuses';
  static badges = Urls.baseUrl + 'badges';
  static widgets = Urls.baseUrl + 'widgets';
  static customer = Urls.baseUrl + 'customer';
  static bookings = Urls.baseUrl + 'bookings';
  static waitList = Urls.baseUrl + 'waitlist';
  static giftcardconfig = Urls.baseUrl + 'gift-card-config';
  static giftcard = Urls.baseUrl + 'gift-card';
  static giftcardcoupon = Urls.baseUrl + 'gift-card-coupon';
  static giftcardtransaction = Urls.baseUrl + 'gift-card-transaction';
  static reports = Urls.baseUrl + 'reports';
  static addfcmtoken = Urls.baseUrl + 'users/add-fcm-token';
  static allnotification = Urls.baseUrl + 'bookings/manager';
  static privateFunction = Urls.baseUrl + 'private-function';
  static managerlist = Urls.baseUrl + 'users/managers';
  static menu = Urls.baseUrl + 'menu';
  static menuBooking = Urls.baseUrl + 'menu-booking';
  static bookingHistory = Urls.baseUrl + 'customer/booking-history';
}
