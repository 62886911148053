// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiUrl: 'https://app.dev.bookya.botpro.in/v1/',
  widgetBaseLink: 'https://booking.dev.bookya.botpro.in/online-booking/',
  takeawayBaseLink: 'https://booking.dev.bookya.botpro.in/online-order/',
  accountWidgetBaseLink: 'https://booking.dev.bookya.botpro.in/widgets/online-widget/all-venue/',
  giftCardBaseLink: 'https://gift-card.dev.bookya.botpro.in/gift-card/',
  accountGiftCardBaseLink: 'https://gift-card.dev.bookya.botpro.in/widgets/online-widget/all-venue/',
  socketUrl: 'https://app.dev.bookya.botpro.in/socket',
  firebase: {
    apiKey: "AIzaSyD65XG83nqGKW_xQAl-u80hWjhWnglZBMk",
    authDomain: "bookya-dff5f.firebaseapp.com",
    projectId: "bookya-dff5f",
    storageBucket: "bookya-dff5f.appspot.com",
    messagingSenderId: "709961829966",
    appId: "1:709961829966:web:7c5548435c2d09a6dcfd1f",
    measurementId: "G-1V8HWZ6NG3"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDService Groupselegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // InSpecific Period Datecluded with Angular CLI.
