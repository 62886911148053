import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { VenueService } from '../../services/venue.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-venue-list',
  templateUrl: './venue-list.component.html',
  styleUrls: ['./venue-list.component.scss'],
})
export class VenueListComponent implements OnInit {
  venueList = [];
  user;
  images = [];
  constructor(private _authService: AuthService, private router: Router, private _venueService: VenueService) {}

  ngOnInit(): void {
    this.user = this._authService.getUser();
    this._venueService.getAllVenue().then((res: any) => {
      this.venueList = res.data.docs;
    });
  }

  onVenueSelect(venue) {
    this._authService.setVenueImage(venue.logo);
    this._authService.setVenue(venue._id);
    this._authService.setTimeZone(venue.timezone);
    this._authService.setVenueName(venue.venueName);
    this._venueService
      .getRoleDetails(venue._id)
      .then((res: any) => {
        this._venueService.setPermission(JSON.stringify(res.data.permissions));
      })
      .catch((err) => {
        console.log(err);
      });
    this.router.navigate(['client']);
  }
}
