<div class="d-flex align-center just-between w-100">
  <div class="d-flex align-center">
    <div class="limit-selection d-flex align-center">
      <label class="font-medium font-dark">Show</label>
      <nb-select class="ml-10" placeholder="Limit" [(selected)]="paginationConfig.limit" (selectedChange)="changeLimit($event)">
        <nb-option [value]="5">5</nb-option>
        <nb-option [value]="10">10</nb-option>
        <nb-option [value]="25">25</nb-option>
        <nb-option [value]="50">50</nb-option>
        <nb-option [value]="75">75</nb-option>
        <nb-option [value]="100">100</nb-option>
      </nb-select>
    </div>
    <div class="records ml-10 font-medium font-light">Records {{ recordStart }} to {{ recordEnd }} of {{ paginationConfig.total }}</div>
  </div>

  <div class="pagination d-flex align-center">
    <label class="font-medium font-light">{{ pagination.totalpage }} pages</label>
    <div class="d-flex ml-10">
      <div class="pagination-icon--left" [ngStyle]="{ cursor: pagination.disablePrev ? 'not-allowed' : 'pointer' }">
        <nb-icon
          class="icon-inner"
          (click)="firstPage()"
          icon="arrowhead-left-outline"
          [options]="{ color: pagination.disablePrev ? '#B8C1CC' : '#66737F', height: '20px', width: '20px' }"
        ></nb-icon>
        <nb-icon
          class="icon-inner"
          (click)="prevPage()"
          icon="arrow-ios-back-outline"
          [options]="{ color: pagination.disablePrev ? '#B8C1CC' : '#66737F', height: '20px', width: '20px' }"
        ></nb-icon>
      </div>
      <input
        (keypress)="restrictNumber($event)"
        class="pagination-input"
        nbInput
        placeholder="no."
        [(ngModel)]="pagination.currentPage"
        (keyup.enter)="pageNumberChange($event)"
      />
      <div class="pagination-icon--right" [ngStyle]="{ cursor: pagination.disableNext ? 'not-allowed' : 'pointer' }">
        <nb-icon
          (click)="nextPage()"
          class="icon-inner"
          icon="arrow-ios-forward-outline"
          [options]="{ color: pagination.disableNext ? '#B8C1CC' : '#66737F', height: '20px', width: '20px' }"
        ></nb-icon>
        <nb-icon
          class="icon-inner"
          (click)="lastPage()"
          icon="arrowhead-right-outline"
          [options]="{ color: pagination.disableNext ? '#B8C1CC' : '#66737F', height: '20px', width: '20px' }"
        ></nb-icon>
      </div>
    </div>
  </div>
</div>
