export class FormBase {
  value: any;
  key: string;
  label: string;
  required: boolean;
  controlType: string;
  type: string;
  placeHolder: string;
  options: any[];
  relatedValue: string;
  elementClass: string;
  code: any;

  constructor(
    options: {
      value?: any;
      key?: string;
      label?: string;
      required?: boolean;
      controlType?: string;
      type?: string;
      placeHolder?: string;
      options?: any[];
      relatedValue?: string;
      elementClass?: string;
      code?: any;
    } = {}
  ) {
    this.value = options.value;
    this.key = options.key || '';
    this.label = options.label || '';
    this.required = !!options.required;
    this.controlType = options.controlType || '';
    this.type = options.type || '';
    this.placeHolder = options.placeHolder || 'Enter ' + options.label.toLowerCase();
    this.options = options.options || [];
    this.relatedValue = options.relatedValue || '';
    this.elementClass = options.elementClass || '';
    this.code = options.code || {};
  }
}
export enum FormControlTypes {
  TEXT = 'text',
  DROPDOWN = 'dropDown',
  FILEUPLOAD = 'fileUpload',
  AUTOCOMPLETE = 'autoComplete',
  PHONE = 'phone',
  STATELIST = 'stateList',
  SUBTITLE = 'subTitle',
}
