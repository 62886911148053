import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.scss'],
})
export class PageHeaderComponent implements OnInit {
  @Input() title;
  @Input() isShowSearchBar: boolean;
  @Input() placeholder;
  @Output() addEmitter = new EventEmitter();
  @Output() onSearchEvent = new EventEmitter();
  @Output() downloadEmitter = new EventEmitter();
  @Input() writePermission = true;
  constructor() {}

  ngOnInit(): void {}

  onAdd(event) {
    this.addEmitter.emit(event);
  }

  onDownload() {
    this.downloadEmitter.emit();
  }

  onSearch(event) {
    this.onSearchEvent.emit(event);
  }
}
