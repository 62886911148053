<div class="w-100 d-flex align-center just-between">
  <div class="d-flex align-center">
    <nb-icon *ngIf="isExpanded" icon="arrow-right-outline"></nb-icon>
    <nb-icon *ngIf="!isExpanded" icon="arrow-down-outline"></nb-icon>
    <p>{{ title }}</p>
  </div>
  <div *ngIf="!isExpanded">
    <button *ngIf="isCancel" nbButton outline status="success" class="accordion-outline-button" (click)="onClose()">Cancel</button>
    <button *ngIf="isSave" class="ml-10 accordion-primary-button" nbButton status="primary" (click)="onSave()">Save</button>
    <button *ngIf="isNext" class="ml-10 accordion-next-button" nbButton status="warning" (click)="onNext()">
      <nb-icon icon="arrow-forward-outline"></nb-icon>
    </button>
  </div>
</div>
