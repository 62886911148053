import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Urls } from '../utils/urls';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class FileUploadService {
  fileChanged: Subject<any> = new Subject();
  fileLoading: Subject<any> = new Subject();
  fileUrl: Subject<any> = new Subject();
  constructor(private http: HttpClient) {}

  uploadImage(data){
    return new Promise((resolve, reject) => {
      this.http.post(Urls.fileUpload + '-image', data).subscribe(
        (res) => {
          resolve(res);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }
}
