<nb-layout>
  <nb-layout-column>
    <div class="h-100 w-100 login-main-container">
      <div class="login-header">
        <img src="assets/images/login-header.svg" />
      </div>
      <div class="login-container">
        <div class="login-inner-container">
          <div class="login-logo">
            <img src="../../../assets/images/logo-white.svg" />
          </div>
          <!--Reset Password Form-->
          <div class="main-form" [formGroup]="resetPassForm">
            <div>
              <label class="font-color-white">New Password</label>
              <nb-form-field class="mt-10 custom-input">
                <nb-icon nbPrefix icon="lock-outline"></nb-icon>
                <input
                  #newPass
                  type="password"
                  nbInput
                  fullWidth
                  placeholder="Enter Password"
                  formControlName="password"
                  [status]="resetPassForm.controls['password'].touched && resetPassForm.controls['password'].invalid ? 'danger' : 'basic'"
                />
                <button class="password-icon" nbSuffix nbButton ghost (click)="toggleShowPassword('new', newPass)">
                  <nb-icon
                    [icon]="isShowNewPass ? 'eye-outline' : 'eye-off-2-outline'"
                    [attr.aria-label]="isShowNewPass ? 'hide password' : 'show password'"
                  >
                  </nb-icon>
                </button>
              </nb-form-field>
            </div>
            <div class="mt-10">
              <label class="font-color-white">Confirm Password</label>
              <nb-form-field class="mt-10 custom-input">
                <nb-icon nbPrefix icon="lock-outline"></nb-icon>
                <input
                  #confirmPass
                  type="password"
                  nbInput
                  fullWidth
                  placeholder="Enter Password"
                  formControlName="confirmPassword"
                  (keyup.enter)="onsetPassword()"
                  [status]="
                    resetPassForm.controls['confirmPassword'].touched && resetPassForm.controls['confirmPassword'].invalid
                      ? 'danger'
                      : 'basic'
                  "
                />
                <button class="password-icon" nbSuffix nbButton ghost (click)="toggleShowPassword('confirm', confirmPass)">
                  <nb-icon
                    [icon]="isShowConfPass ? 'eye-outline' : 'eye-off-2-outline'"
                    [attr.aria-label]="isShowConfPass ? 'hide password' : 'show password'"
                  >
                  </nb-icon>
                </button>
              </nb-form-field>
            </div>
            <div class="d-flex just-end">
              <p class="forgot-password" (click)="goToLogin()">Back To Login</p>
            </div>
            <div class="text-center">
              <button class="primary-button mt-20" status="primary" nbButton (click)="onsetPassword()">Set Password</button>
            </div>
          </div>
        </div>
      </div>
      <div class="login-footer w-100">
        <div>&copy; 2021 <span class="font-bold">BookYa</span> - All Rights Reserved</div>
      </div>
    </div>
  </nb-layout-column>
</nb-layout>
