import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-common-table-layout',
  templateUrl: './common-table-layout.component.html',
  styleUrls: ['./common-table-layout.component.scss'],
})
export class CommonTableLayoutComponent implements OnInit {
  @Input() data;
  @Input() table;
  @Input() isLoading;
  @Input() isOnlyRead = false;
  @Input() isNotFireEditWithNavigation;
  @Output() onAction = new EventEmitter<any>();
  @Output() onNavigation = new EventEmitter<any>();
  @Output() clickOnRow = new EventEmitter<any>();
  @Input() toolTip = 'noop';
  isAllChecked = false;
  isNoneChecked = true;
  isTrainButtonDisabled = false;
  lastName;
  downArrow = false;
  toolTipData;
  visibleIcon = false;
  @Input() sortingType;
  @Input() sortField;
  @Input() isSimpleView;
  constructor(public _router: Router) {}

  ngOnInit() {
    if (this.table) {
      this.table.rows.map((d) => (d.isCheckValue = false));
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.data && changes.data.currentValue && changes.data.currentValue.length < 1) {
      this.isAllChecked = false;
    }
    if (changes.data && changes.data.currentValue && changes.data.currentValue.length > 0) {
      changes.data.currentValue.forEach((val) => {
        if (!val['isChecked']) {
          this.isAllChecked = false;
        }
      });
    }
  }

  ngOnDestroy(): void {}

  performAction(type: any, index: number) {
    this.onAction.emit({ type: type, index: index });
  }
  rowClick(index: number) {
    this.clickOnRow.emit({ index: index });
  }

  selectAll(value) {
    this.isTrainButtonDisabled = value;
    this.isAllChecked = value;
    this.isNoneChecked = !value;
    let dataId = [];
    this.data.forEach((t) => {
      t['isChecked'] = value;
      if (value) {
        dataId.push(t._id);
      }
    });
    this.onAction.emit({ type: 'train', trainType: 'multiple', dataId });
  }

  selectSingle(value, index?) {
    this.data[index].isActive = value;
    let flag = false;
    let dataId = [];
    if (!value) {
      this.isAllChecked = false;
      this.data.forEach((t) => {
        if (t['isActive']) {
          dataId.push(t._id);
          flag = true;
        }
      });
      if (!flag) {
        this.isNoneChecked = true;
      }
    } else {
      this.isNoneChecked = false;
      this.data.forEach((t) => {
        if (!t['isActive']) {
          flag = true;
        } else {
          dataId.push(t._id);
        }
      });
      if (!flag) {
        this.isAllChecked = true;
      }
    }
    this.onAction.emit({ type: 'checkValue', index, value });
  }

  openNavigation(id, index) {
    if (this.isNotFireEditWithNavigation) {
      this.onAction.emit({ type: 'select', id, index: index });
    } else {
      if (this.table.navigation && this.table.navigation.length > 0) {
        this.table.navigation.push(id);
        this._router.navigate(this.table.navigation, {
          queryParams: this.table.queryParams,
        });
      }
    }
  }

  stopRoution(event: any) {
    event.stopPropagation();
  }

  saveData(event, id, index) {
    if (event.target.value === this.lastName || event.target.value.trim() === '') {
      this.data[index].flowName = this.lastName;
      this.lastName = '';
      return;
    }
    this.onAction.emit({ type: 'rename', value: event.target.value, id: id });
    event.target.blur();
  }

  trainData(dataId, dataIndex) {
    this.onAction.emit({
      type: 'train',
      trainType: 'single',
      dataId,
      trainingIndex: dataIndex,
    });
  }

  saveLastName(index) {
    this.lastName = '';
    this.lastName = this.data[index].flowName;
  }

  sortList(index, value, isSorting) {
    if (isSorting) {
      if (this.sortField === value) {
        if (this.sortingType === 1) {
          this.sortingType = -1;
        } else {
          this.sortingType = 1;
        }
      }
      this.sortField = value;
      this.onAction.emit({
        type: 'sorting',
        sortingType: this.sortingType,
        sortField: value,
      });
      this.table.rows.forEach((rowList, ind) => {
        if (index === ind) {
          rowList.isCheckValue = !rowList.isCheckValue;
        } else {
          rowList.isCheckValue = false;
        }
      });
    }
  }
  archive(event, index) {
    this.onAction.emit({ type: 'changeChecked', value: event, index: index });
  }
  over(data){
    this.toolTipData = data;
  }
}
