<nb-layout>
  <nb-layout-column>
    <div class="h-100 w-100 login-main-container">
      <div class="login-header">
        <img src="assets/images/login-header.svg" />
      </div>
      <div class="login-container">
        <div class="login-inner-container">
          <div class="login-logo">
            <img src="../../../assets/images/logo-white.svg" />
          </div>
          <div class="d-flex just-between align-center login-form-label">All your permissions are disabled , Please Contact admin.</div>
          <div class="w-100 d-flex just-center align-center mt-10">
            <button nbButton status="primary" (click)="onLogout()">Logout</button>
          </div>

        </div>
      </div>
      <div class="login-footer w-100">
        <div>&copy; 2021 <span class="font-bold">BookYa</span> - All Rights Reserved</div>
      </div>
    </div>
  </nb-layout-column>
</nb-layout>
