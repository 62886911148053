import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NbThemeModule, NbLayoutModule, NbMenuModule } from '@nebular/theme';
import { NbEvaIconsModule } from '@nebular/eva-icons';
import { AppRoutingModule } from './app-routing.module';
import { LoginComponent } from './components/login/login.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AuthService } from './services/auth.service';
import { NotificationService } from './services/notification.service';
import { TokenInterceptor } from './utils/interceptor/token.interceptor';
import { SharedModule } from './shared.module';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { SetResetPasswordComponent } from './components/set-reset-password/set-reset-password.component';
import { AuthGuard } from './utils/guard/auth.guard';
import { VenueListComponent } from './components/venue-list/venue-list.component';
import { ChartsModule } from 'ng2-charts';
import { MessagingService } from './services/messaging.service';
import { AngularFireModule } from '@angular/fire';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { environment } from '../environments/environment';
import { FcmService } from './services/fcmservices.service';
import { VenueService } from './services/venue.service';
import { PermissionDeniedComponent } from './components/permissionDenied/permission-denied.component';
import { AdminPermissionGuard } from './utils/guard/adminPermission.guard';
import { SocketIoConfig, SocketIoModule } from 'ngx-socket-io';

const config: SocketIoConfig = {
  url: environment.socketUrl,
};

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ForgotPasswordComponent,
    SetResetPasswordComponent,
    VenueListComponent,
    PermissionDeniedComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    NbThemeModule.forRoot({ name: 'corporate' }),
    NbEvaIconsModule,
    AppRoutingModule,
    SharedModule,
    NbMenuModule.forRoot(),
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    ChartsModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireMessagingModule,
    SocketIoModule.forRoot(config),
  ],
  providers: [
    AuthService,
    AuthGuard,
    AdminPermissionGuard,
    MessagingService,
    FcmService,
    NotificationService,
    VenueService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
