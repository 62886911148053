<div [formGroup]="form">
  <label *ngIf="question.controlType !== 'subTitle'" [attr.for]="question.key"
    >{{ question.label }} <span *ngIf="question.required">*</span></label
  >
  <div class="mt-20 form-sub-header font-color-primary" *ngIf="question.controlType === 'subTitle'">{{ question.label }}</div>

  <div [ngSwitch]="question.controlType">
    <input
      class="mt-5"
      nbInput
      fullWidth
      *ngSwitchCase="'text'"
      [formControlName]="question.key"
      [type]="question.type"
      [placeholder]="question.placeHolder"
      [status]="form.controls[question.key].touched && form.controls[question.key].invalid ? 'danger' : 'basic'"
    />

    <nb-select
      *ngSwitchCase="'dropDown'"
      class="mt-5"
      fullWidth
      [placeholder]="question.placeHolder"
      [formControlName]="question.key"
      [status]="form.controls[question.key].touched && form.controls[question.key].invalid ? 'danger' : 'basic'"
    >
      <nb-option *ngFor="let opt of question.options" [value]="opt.key">{{ opt.value }}</nb-option>
    </nb-select>
    <nb-form-field *ngSwitchCase="'phone'" class="mt-5 phone-input">
      <input
        nbInput
        fullWidth
        placeholder="Enter phone"
        (keypress)="restrictNumber($event)"
        [formControlName]="question.key"
        [status]="form.controls[question.key].touched && form.controls[question.key].invalid ? 'danger' : 'basic'"
      />
      <nb-select
        nbPrefix
        placeholder="code"
        [(selected)]="question.code"
        optionsListClass="custom-option-list"
        [status]="form.controls[question.key].touched && form.controls[question.key].invalid ? 'danger' : 'basic'"
      >
        <nb-select-label>
          <div class="d-flex align-center phone-select-label">
            <img height="18px" width="22px" [src]="question.code?.flag" />
            <span class="ml-5 font-regular">{{ question.code?.callingCodes }}</span>
          </div>
        </nb-select-label>
        <nb-option *ngFor="let code of question.options" [value]="code">
          <div class="d-flex align-center just-between w-100">
            <div class="d-flex align-center">
              <img height="20px" width="28px" [src]="code.flag" />
              <div class="ml-10">{{ code.name }}</div>
            </div>
            <div class="ml-10">{{ code.callingCodes }}</div>
          </div>
        </nb-option>
      </nb-select>
    </nb-form-field>
    <div
      *ngSwitchCase="'fileUpload'"
      class="logo-container mt-5"
      (click)="file.click()"
      [nbSpinner]="isFileLoading"
      nbSpinnerStatus="primary"
      nbSpinnerSize="giant"
    >
      <div *ngIf="!fileUrl">
        <nb-icon class="menu-icon" icon="cloud-upload-outline" [options]="{ color: '#66737F' }"></nb-icon>
      </div>
      <img *ngIf="fileUrl" [src]="fileUrl" />
      <input #file type="file" hidden (change)="fileUpload($event)" />
    </div>
    <div *ngSwitchCase="'autoComplete'">
      <input
        class="mt-5"
        #autoInput
        nbInput
        fullWidth
        type="text"
        [placeholder]="question.placeHolder"
        (input)="onChange()"
        [nbAutocomplete]="auto"
        [formControlName]="question.key"
        [status]="form.controls[question.key].dirty && form.controls[question.key].invalid ? 'danger' : 'basic'"
      />

      <nb-autocomplete #auto (selectedChange)="onSelectionChange($event)" [handleDisplayFn]="viewHandle">
        <nb-option *ngFor="let country of filteredOptions$ | async" [value]="country">
          <div class="d-flex align-center">
            <div>{{ country.code }}</div>
            <div class="ml-10">{{ country.name }}</div>
          </div>
        </nb-option>
      </nb-autocomplete>
    </div>
    <div *ngSwitchCase="'stateList'">
      <input
        class="mt-5"
        #states
        nbInput
        fullWidth
        type="text"
        [placeholder]="question.placeHolder"
        (input)="onStateChange()"
        [formControlName]="question.key"
        [nbAutocomplete]="autoState"
        [focusInputOnValueChange]="false"
        [status]="form.controls[question.key].dirty && form.controls[question.key].invalid ? 'danger' : 'basic'"
      />

      <nb-autocomplete #autoState (selectedChange)="onStateSelectionChange($event)">
        <nb-option *ngFor="let state of filteredStates$ | async" [value]="state">
          <div class="d-flex align-center">
            <div>{{ state }}</div>
          </div>
        </nb-option>
      </nb-autocomplete>
    </div>
  </div>
</div>
