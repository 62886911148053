import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import 'quill-mention';

@Component({
  selector: 'app-quill-editor',
  templateUrl: './quill-editor.component.html',
  styleUrls: ['./quill-editor.component.scss'],
})
export class QuillEditorComponent implements OnInit, OnChanges {
  @Input() quillText;
  @Output() quillTextChanged = new EventEmitter();
  @ViewChild('quillEditor') quillEditor;
  showQuill = false;

  modules = {
    toolbar: [
      ['bold', 'italic'],
      [{ list: 'ordered' }, { list: 'bullet' }],
    ],
    mention: {
      allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
      mentionDenotationChars: ['^'],
      mentionContainerClass: 'ql-mention-list-container',
      mentionListClass: 'ql-mention-list',
      listItemClass: 'ql-mention-list-item',
      source: function (searchTerm, renderList, mentionChar) {
        let values;

        if (mentionChar === '^') {
          values = [
            { id: 1, value: 'bookingDateTime' },
            { id: 2, value: 'name' },
            { id: 3, value: 'createdAt' },
            { id: 4, value: 'people' },
            { id: 5, value: 'venueName' },
            { id: 6, value: 'address' },
            { id: 7, value: 'contactNumber' },
            { id: 8, value: 'Service' },
            { id: 9, value: 'sectionName' },
            { id: 10, value: 'customerNotes' },
            { id: 11, value: 'type' },
            { id: 12, value: 'amount' },
            { id: 13, value: 'promotionalInformation' },
          ];
        }

        if (searchTerm.length === 0) {
          renderList(values, searchTerm);
        } else {
          const matches = [];
          for (let i = 0; i < values.length; i++)
            if (~values[i].value.toLowerCase().indexOf(searchTerm.toLowerCase())) matches.push(values[i]);
          renderList(matches, searchTerm);
        }
      },
      onSelect: (item, insertItem) => {
        const editor = this.quillEditor.quillEditor;
        editor.insertText(editor.getLength() - 1, item.value + '^', 'user');
      },
    },
  };
  quillEditorText = '';
  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.quillText && changes.quillText.currentValue) {
      this.quillEditorText = JSON.parse(JSON.stringify(this.quillText));
    }
    setTimeout(() => {
      this.showQuill = true;
    }, 100);
  }

  ngOnInit(): void {
    if (this.quillText) {
      setTimeout(() => {
        this.quillEditorText = JSON.parse(JSON.stringify(this.quillText));
        this.showQuill = true;
      }, 100);
    }
  }

  changeValue(event) {
    this.quillTextChanged.emit({ event });
  }
}
