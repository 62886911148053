<nb-layout>
  <nb-layout-column>
    <div class="h-100 w-100 login-main-container">
      <div class="login-header">
        <img src="assets/images/login-header.svg" />
      </div>
      <div class="login-container">
        <div class="login-inner-container">
          <div class="login-logo">
            <img src="../../../assets/images/logo-white.svg" />
          </div>
          <!--Forget Password Form-->
          <div [formGroup]="forgotPassForm">
            <div class="mt-30">
              <label class="font-color-white">Email</label>
              <nb-form-field class="mt-10 custom-input">
                <nb-icon nbPrefix icon="email-outline"></nb-icon>
                <input
                  type="text"
                  nbInput
                  fullWidth
                  placeholder="Enter Email"
                  formControlName="email"
                  (keyup.enter)="onForgotPassword()"
                  [status]="forgotPassForm.controls['email'].touched && forgotPassForm.controls['email'].invalid ? 'danger' : 'basic'"
                />
              </nb-form-field>
              <div class="d-flex just-end">
                <p class="forgot-password" (click)="goToLogin()">Back To Login</p>
              </div>
              <div class="text-center">
                <button class="primary-button mt-20" status="primary" nbButton (click)="onForgotPassword()">Send Email</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="login-footer w-100">
        <div>&copy; 2021 <span class="font-bold">BookYa</span> - All Rights Reserved</div>
      </div>
    </div>
  </nb-layout-column>
</nb-layout>
