import { Injectable } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { BehaviorSubject } from 'rxjs';
import { NbIconConfig, NbToastrService } from '@nebular/theme';
import { NotificationService } from './notification.service';

@Injectable()
export class MessagingService {
  static fcmToken;
  currentMessage = new BehaviorSubject(null);
  constructor(
    private angularFireMessaging: AngularFireMessaging,
    private notificationService: NotificationService,
    private toastrService: NbToastrService
  ) {}
  requestPermission() {
    this.angularFireMessaging.requestToken.subscribe(
      (token) => {
        MessagingService.fcmToken = token;
      },
      (err) => {
        console.error('Unable to get permission to notify.', err);
      }
    );
  }
  receiveMessage() {
    this.angularFireMessaging.messages.subscribe((payload) => {
      if (payload) {
        this.showToast('bottom-end', payload);
      }
    });
  }

  showToast(position, payload) {
    this.toastrService.show(payload.notification.body, payload.notification.title, { position });
  }
}
