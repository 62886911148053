import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'common-table',
  templateUrl: './common-table.component.html',
  styleUrls: ['./common-table.component.scss'],
})
export class CommonTableComponent implements OnInit {
  @Input() tableStructure;
  @Input() tableData;
  @Output() actionEmitter = new EventEmitter();
  @Output() onNavigation = new EventEmitter();

  constructor(private router: Router) {}

  ngOnInit(): void {}

  onActionClick(event, actionType, index, id) {
    if (actionType === 'delete') {
      this.actionEmitter.emit({ event, type: actionType, index: index });
    } else {
      if (this.tableStructure.navigation && this.tableStructure.navigation.length > 0) {
        this.tableStructure.navigation.push(id);
        this.router.navigate(this.tableStructure.navigation, {
          queryParams: this.tableStructure.queryParams,
        });
      } else {
        this.actionEmitter.emit({ event, type: actionType, index: index });
      }
    }
  }
}
