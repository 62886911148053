<!-- MAIN TABLE | START-->
<ul class="table-ul" [ngClass]="{ isSimpleView: isSimpleView }">
  <!-- TABLE-HEADER | START-->
  <li>
    <div *ngIf="table.isCheckBox" class="flow-wrap select-column"></div>
    <div
      *ngFor="let row of table.rows; let i = index"
      class="flow-wrap"
      [style.width]="row.width + '%'"
      (click)="sortList(i, row.value, row.isSorting)"
      [ngClass]="{ 'justify-content-center': row.flag === 'center' }"
    >
      {{ row.name }}
      <div class="sorting-arrow" *ngIf="row.isSorting" [class.rotation-none]="sortingType === 1" [class.visible]="row.isCheckValue">
        <img src="../../../../assets/images/down-arrow-wrap.svg" />
      </div>
    </div>
    <div *ngIf="table.actions && table.actions.length > 0" class="flow-wrap">Action</div>
  </li>
  <!-- TABLE-HEADER | END-->

  <!-- TABLE-BODY | START-->
  <li *ngFor="let rule of data; let dIndex = index" (click)="rowClick(dIndex)">
    <div *ngIf="table.isCheckBox" class="flow-wrap select-column">
      <nb-toggle [(ngModel)]="rule['isActive']" (ngModelChange)="selectSingle($event, dIndex)" [disabled]="isOnlyRead"></nb-toggle>
    </div>

    <div
      class="flow-wrap"
      *ngFor="let row of table.rows; index as rIndex"
      [style.width]="row.width + '%'"
      [ngClass]="{
        'c-p': table.navigation && table.navigation.length > 0,
        'justify-content-center': row.flag === 'center',
        approved: rule[row.value] === 'approved',
        pending: rule[row.value] === 'pending',
        rejected: rule[row.value] === 'rejected'
      }"
      (click)="openNavigation(rule._id, rIndex)"
    >
      <ng-container [ngSwitch]="row.type">
        <div *ngSwitchCase="'week'" class="d-flex align-items-center w-100">
          <div class="weeks">
            <div *ngFor="let day of rule[row.value]; index as rIndex">
              {{ day.day | slice: 0:3 }}
            </div>
          </div>
        </div>
        <div *ngSwitchCase="'archive'" class="d-flex align-items-center w-100">
          <nb-checkbox [checked]="rule[row.value]" (checkedChange)="archive($event, dIndex)" [disabled]="isOnlyRead"> </nb-checkbox>
        </div>
        <span class="data_overflow" *ngSwitchCase="'text'" [ngClass]="{ 'text-center': row.flag === 'center' }" [nbPopover]="templateRef"
              [nbPopoverTrigger]="toolTip" (mouseover)="over(rule[row.value])"
          >{{rule[row.value] !== undefined ? (rule[row.value]) : 'N/A'}}
        </span>
        <span *ngSwitchCase="'email'" [ngStyle]="{ 'text-transform': 'none' }"
          >{{ rule[row.value] !== undefined ? rule[row.value] : "N/A" }}
        </span>
        <span *ngSwitchCase="'code'" [ngStyle]="{ 'text-transform': 'none' }"
          >{{ rule[row.value] !== undefined ? rule[row.value] : "N/A" }}
        </span>
        <div *ngSwitchCase="'date'" class="edit-date">
          <span>{{ rule[row.value] !== undefined ? (rule[row.value] | date: "d MMM,y") : "N/A" }}</span>
        </div>
        <div *ngSwitchCase="'time'" class="edit-date">
          <span>{{ rule[row.value] !== undefined ? (rule[row.value] | date: "h:mm a") : "N/A" }}</span>
        </div>
        <div *ngSwitchCase="'icon'" class="edit-date">
          <nb-icon [icon]="rule[row.icon]" [options]="rule[row.iconOptions]" [nbPopoverTrigger]="row.icon === 'icon' ? 'hover' : 'noop'" [nbPopover]="notetemplateRef"></nb-icon>
          <ng-template #notetemplateRef>
            {{row[row.value]}}
            <div class="p-10" *ngIf="row.value === 'notes'">
              <p class="m-0 mb-10">Customer Note : {{rule[row.customerNote] | json}}</p>
              <p class="m-0 mb-10">Manager Note : {{rule[row.managerNote] | json}}</p>
            </div>
            <div class="p-10 template-style" *ngIf="row.value === 'description'" [innerHTML]="rule[row.value]">
            </div>
          </ng-template>
        </div>
        <div *ngSwitchCase="'iconList'" class="d-flex align-center">
          <div class="color-box" *ngFor="let icon of rule[row.value]">
            <span class="material-icons-round" [ngStyle]="{ color: icon.color }">{{ icon.name }} </span>
          </div>
        </div>

        <div class="tooltip" *ngIf="row.isTooltip">
          <button [nbPopover]="templateRef" class="tooltip-btn" [nbPopoverTrigger]="'hover'">
            <nb-icon icon="alert-circle-outline" [options]="{ color: '#FBBC05' }"></nb-icon>
          </button>
          <ng-template #templateRef>
            <div class="p-10" *ngIf="row.toolTipType !== 'html'">
              <p class="m-0 mb-10" *ngFor="let valus of rule[row.valueAll]; index as rIndex">{{ valus }}</p>
            </div>
            <div class="p-10" *ngIf="row.toolTipType === 'html'">
              <p class="m-0 mb-10" [innerHTML]="valus" *ngFor="let valus of rule[row.valueAll]; index as rIndex"></p>
            </div>
          </ng-template>
        </div>
      </ng-container>
    </div>

    <div *ngIf="table.actions && table.actions.length > 0" class="flow-wrap">
      <ng-container>
        <button class="action-btn" style="height: 40px" *ngFor="let action of table.actions" (click)="performAction(action.type, dIndex); $event.stopPropagation()">
          <div class="flow-action-main">
            <nb-icon [icon]="action.icon"></nb-icon>
          </div>
        </button>
      </ng-container>
    </div>
  </li>
  <!-- TABLE-BODY | END-->
</ul>
<!-- MAIN TABLE | END-->
<ng-template #templateRef>
  <div class="p-10">
    <p class="m-0 mb-10" [innerHTML]="toolTipData"></p>
  </div>
</ng-template>

