<section id="header">
  <div class="menu">
    <div class="header-inner">
      <div class="head">
        <img src="assets/images/logo-white-header.svg" alt="" />
        <p>{{ this.authService.getVenueName() }}</p>
        <button class="ml-10 menu-bar-icon" (click)="closeMenu()" nbButton>
          <nb-icon icon="close-circle-outline" [options]="{ color: '#1D1E3A' }"></nb-icon>
        </button>
      </div>
      <div class="header-content">
        <div class="row">
          <div class="w-100">
            <ul *ngIf="moduleType === 'admin'">
              <li class="admin" [routerLink]="['/', 'client']" (click)="closeMenu()">
                <div class="icon">
                  <nb-icon icon="arrow-circle-left-outline" [options]="{ color: '#ffffff' }"></nb-icon>
                </div>
                <div class="content">
                  <p>Back to Booking System</p>
                </div>
              </li>
              <li routerLink="restaurants" routerLinkActive="active" (click)="closeMenu()">
                <div class="icon">
                  <nb-icon icon="people-outline" [options]="{ color: '#ffffff' }"></nb-icon>
                </div>
                <div class="content">
                  <p>Restaurants</p>
                </div>
              </li>
              <li routerLink="managers" routerLinkActive="active" (click)="closeMenu()">
                <div class="icon">
                  <nb-icon icon="briefcase-outline" [options]="{ color: '#ffffff' }"></nb-icon>
                </div>
                <div class="content">
                  <p>Managers</p>
                </div>
              </li>
              <li routerLink="settings" routerLinkActive="active">
                <div class="icon">
                  <nb-icon icon="settings-outline" [options]="{ color: '#ffffff' }"></nb-icon>
                </div>
                <div class="content">
                  <p>Account Details</p>
                </div>
              </li>
            </ul>
            <ul *ngIf="moduleType === 'client'">
              <li routerLink="bookings" routerLinkActive="active" (click)="closeMenu()">
                <div class="icon">
                  <nb-icon icon="calendar-outline" [options]="{ color: '#ffffff' }"></nb-icon>
                </div>
                <div class="content">
                  <p>Booking</p>
                </div>
              </li>
              <li routerLink="customers" routerLinkActive="active" (click)="closeMenu()">
                <div class="icon">
                  <nb-icon icon="people-outline" [options]="{ color: '#ffffff' }"></nb-icon>
                </div>
                <div class="content">
                  <p>Customers</p>
                </div>
              </li>
              <li routerLink="reports" routerLinkActive="active" (click)="closeMenu()">
                <div class="icon">
                  <nb-icon icon="pie-chart-outline" [options]="{ color: '#ffffff' }"></nb-icon>
                </div>
                <div class="content">
                  <p>Reports</p>
                </div>
              </li>
              <li routerLink="settings" routerLinkActive="active" (click)="closeMenu()">
                <div class="icon">
                  <nb-icon icon="settings-outline" [options]="{ color: '#ffffff' }"></nb-icon>
                </div>
                <div class="content">
                  <p>Settings</p>
                </div>
              </li>
              <li class="admin" [routerLink]="['/', 'admin']" (click)="closeMenu()">
                <div class="icon">
                  <nb-icon icon="shield-outline" [options]="{ color: '#ffffff' }"></nb-icon>
                </div>
                <div class="content">
                  <p>Admin</p>
                </div>
              </li>
            </ul>
          </div>
<!--          <div class="col-md-4">-->
<!--            <ul *ngIf="moduleType === 'admin'" (click)="closeMenu()">-->

<!--            </ul>-->
<!--          </div>-->
        </div>
      </div>
    </div>
  </div>
</section>
