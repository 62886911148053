<nb-select
  class="mt-5"
  multiple
  placeholder="Select tables"
  fullWidth
  [disabled]="disabled"
  [(selected)]="selectedValues"
  (selectedChange)="onSelectionChange($event)"
>
  <nb-select-label>
    <div class="d-flex align-center phone-select-label">
      <div class="add-table-list d-flex align-center" *ngFor="let tableItem of displayDataList; let tagI = index">
        <p class="m-0">{{ tableItem.name }} - C{{ tableItem.capacity }}</p>
        <nb-icon icon="close-circle-outline" [options]="{ color: '#ffffff' }" (click)="removeSelection(tagI, $event)"></nb-icon>
      </div>
    </div>
  </nb-select-label>
  <nb-option [disabled]="disabled" *ngFor="let listItem of list" [value]="listItem._id">{{
    isTable ? listItem.name + "- C" + listItem.capacity : listItem.name
  }}</nb-option>
</nb-select>
