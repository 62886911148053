<section class="week-time-icker">
  <div class="page-title"><h2>Days of the week</h2></div>
  <div class="week-picker" *ngFor="let elementData of timeSchedule; let cIndex = index">
    <div class="toggle-switch">
      <nb-toggle [(checked)]="elementData.isEnable" (checkedChange)="getChecked($event, cIndex)"></nb-toggle>
    </div>
    <div class="week-name">
      <p>{{ elementData.day | titlecase }}</p>
    </div>
    <div class="from" [ngClass]="{ disabled: !elementData.isEnable }">
      <nb-icon icon="clock-outline"></nb-icon>
      <input
        nbInput
        type="text"
        fullWidth
        fieldSize="large"
        class="w-100"
        placeholder="From"
        [(ngModel)]="elementData.openTime"
        (ngModelChange)="checkDateForChanges($event, cIndex, 'openTime')"
        [nbTimepicker]="timepicker"
      />

      <nb-timepicker ampmText="AM/PM" (onSelectTime)="(elementData.openTime)" #timepicker twelveHoursFormat></nb-timepicker>
    </div>
    <div class="to" [ngClass]="{ disabled: !elementData.isEnable }">
      <nb-icon icon="clock-outline"></nb-icon>
      <input
        nbInput
        type="text"
        [(ngModel)]="elementData.closeTime"
        (ngModelChange)="checkDateForChanges($event, cIndex, 'closeTime')"
        fullWidth
        fieldSize="large"
        class="w-100"
        placeholder="To"
        [nbTimepicker]="timepicker1"
      />

      <nb-timepicker ampmText="AM/PM" (onSelectTime)="(elementData.closeTime)" #timepicker1 twelveHoursFormat></nb-timepicker>
    </div>
    <div class="reservation" [ngClass]="{ disabled: !elementData.isEnable }">
      <nb-icon icon="clock-outline"></nb-icon>
      <input
        nbInput
        type="text"
        [(ngModel)]="elementData.lastBookingTime"
        (ngModelChange)="checkDateForChanges($event, cIndex, 'lastBookingTime')"
        fullWidth
        fieldSize="large"
        class="w-100"
        placeholder="Last Reservation Time"
        [nbTimepicker]="timepicker2"
      />

      <nb-timepicker ampmText="AM/PM" (onSelectTime)="(elementData.lastBookingTime)" #timepicker2 twelveHoursFormat></nb-timepicker>
    </div>
  </div>
</section>
