import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Urls } from '../utils/urls';

@Injectable()
export class AuthService {
  token;
  user;
  email;
  constructor(private http: HttpClient) {
    const t = localStorage.getItem('token');
    this.token = t ? t : null;
    const u = localStorage.getItem('user');
    this.user = u ? u : null;
  }

  setToken(token) {
    localStorage.setItem('token', token);
  }

  getToken() {
    if (localStorage.getItem('token')) {
      return localStorage.getItem('token');
    }
  }

  setVenueAddress(address) {
    localStorage.setItem('venueAddress', address);
  }

  setVenueImage(imgurl) {
    localStorage.setItem('venueImage', imgurl);
  }
  getVenueImage() {
    return localStorage.getItem('venueImage');
  }
  getVenueAddress() {
    return localStorage.getItem('venueAddress');
  }

  setVenueName(venueName) {
    localStorage.setItem('venueName', venueName);
  }

  getVenueName() {
    return localStorage.getItem('venueName');
  }

  setUser(user) {
    localStorage.setItem('user', user);
  }

  setTimeZone(timezone) {
    localStorage.setItem('timezone', timezone);
  }

  getTimeZone() {
    return localStorage.getItem('timezone');
  }

  getUser() {
    if (localStorage.getItem('user')) {
      const user = JSON.parse(localStorage.getItem('user'));
      if (typeof user !== 'object') {
        this.logout();
      } else {
        return JSON.parse(localStorage.getItem('user'));
      }
    }
  }

  setVenue(venueid) {
    localStorage.setItem('venueId', venueid);
  }

  getVenue() {
    return localStorage.getItem('venueId');
  }

  login(data) {
    return new Promise((resolve, reject) => {
      this.http.post(Urls.login, data).subscribe(
        (res) => {
          this.token = res['data']['token'];
          this.user = res['data']['user'];
          localStorage.setItem('token', this.token);
          localStorage.setItem('user', JSON.stringify(this.user));
          resolve(res);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }
  logout() {
    return new Promise((resolve, reject) => {
      this.http
        .delete(Urls.logout, {
          headers: { Authorization: this.getToken() },
        })
        .subscribe(
          (res) => {
            localStorage.clear();
            sessionStorage.clear();
            this.user = '';
            this.token = '';
            resolve(res);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }
  forgotPassword(data) {
    return new Promise((resolve, reject) => {
      this.http.post(Urls.forgotPassword, data).subscribe(
        (res) => {
          resolve(res);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }
  resetPassword(data, userId) {
    return new Promise((resolve, reject) => {
      this.http.post(Urls.auth + '/' + userId + '/reset-password', data).subscribe(
        (res) => {
          resolve(res);
        },
        (err) => {
          reject(err);
          console.error(err);
        }
      );
    });
  }
  setPassword(data, userId) {
    return new Promise((resolve, reject) => {
      this.http.put(Urls.auth + '/' + userId + '/set-password', data).subscribe(
        (res) => {
          resolve(res);
        },
        (err) => {
          reject(err);
          console.error(err);
        }
      );
    });
  }
  getImageByAccount(fileName, type, adminid?, venueId?) {
    return new Promise((resolve, reject) => {
      this.http.get(Urls.images + '?fileName=' + fileName + '&businessId=' + adminid + '&type=' + type + '&venueId=' + venueId).subscribe(
        (res) => {
          resolve(res);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }
  setAccessLevel(access) {
    localStorage.setItem('access', access);
  }
  getAccessLevel() {
    return localStorage.getItem('access');
  }
}
