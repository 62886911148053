<nb-layout>
  <nb-layout-column>
    <div class="h-100 w-100 venue-main-container">
      <div class="venue-header">
        <img src="assets/images/login-header.svg" />
      </div>
      <div class="login-container">
        <div class="login-inner-container">
          <div class="venue-logo">
            <img src="../../../assets/images/logo-white.svg" />
          </div>

          <div class="venue-list">
            <div class="venue-card" (click)="onVenueSelect(venue)" *ngFor="let venue of venueList; let i = index">
              <div class="card-img">
                <img [src]="venue.logo" />
              </div>
              <div class="card-text">
                <p>{{ venue.venueName }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="venue-footer w-100">
        <div>&copy; 2021 <span class="font-bold">BookYa</span> - All Rights Reserved</div>
      </div>
    </div>
  </nb-layout-column>
</nb-layout>
