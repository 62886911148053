import { FormBase, FormControlTypes } from '../utils/helper/FormBase';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Injectable } from '@angular/core';

@Injectable()
export class FormControlService {
  constructor() {}
  toFormGroup(questions: FormBase[]) {
    const group: any = {};

    questions.forEach((question) => {
      let validators = [];
      if (question.required) {
        validators.push(Validators.required);
      }
      if (question.type === 'email') {
        validators.push(Validators.email);
      }
      if (question.type === 'url') {
        validators.push(
          Validators.pattern("^(?:http(s)?:\\/\\/)?[\\w.-]+(?:\\.[\\w\\.-]+)+[\\w\\-\\._~:/?#[\\]@!\\$&'\\(\\)\\*\\+,;=.]+$")
        );
      }
      if (question.controlType !== FormControlTypes.SUBTITLE) {
        group[question.key] = validators.length ? new FormControl(question.value || '', validators) : new FormControl(question.value || '');
      }
    });
    return new FormGroup(group);
  }
}
