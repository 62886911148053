import { NgModule } from '@angular/core';
import { NebularModule } from './nebular.module';
import { SearchComponent } from './ui-components/search/search.component';
import { CommonTableComponent } from './ui-components/common-table/common-table.component';
import { PageHeaderComponent } from './ui-components/page-header/page-header.component';
import { PaginationComponent } from './ui-components/pagination/pagination.component';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HeaderComponent } from './components/header/header.component';
import { DynamicFormComponent } from './ui-components/dynamic-form/dynamic-form.component';
import { DynamicFormQuestionsComponent } from './ui-components/dynamic-form/dynamic-form-questions/dynamic-form-questions.component';
import { FormControlService } from './services/form-control.service';
import { FileUploadService } from './services/file-upload.service';
import { CountriesService } from './services/countries.service';
import { RouterModule } from '@angular/router';
import { SideNavComponent } from './ui-components/side-nav/side-nav.component';
import { CommonTableLayoutComponent } from './ui-components/common-table-layout/common-table-layout.component';
import { WeekTimePickerComponent } from './ui-components/week-time-picker/week-time-picker.component';
import { CommonFormComponent } from './ui-components/common-form/common-form.component';
import { AccordianHeaderComponent } from './ui-components/accordian-header/accordian-header.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { CommonTableListComponent } from './ui-components/common-table-list/common-table-list.component';
import { NameSeparatePipe } from './utils/pipes/name-seprate.pipe';
import { ColorPickerModule } from 'ngx-color-picker';

import { QuillModule } from 'ngx-quill';
import { QuillEditorComponent } from './components/quill-editor/quill-editor.component';
import { PhoneInputComponent } from './ui-components/phone-input/phone-input.component';
import { MultiSelectComponent } from './ui-components/multi-select/multi-select.component';
import { NbPopoverModule } from '@nebular/theme';
import { TimeFormatPipe } from './utils/helper/timeformat.pipe';
import { ExcelService } from './services/excel-file.service';
@NgModule({
  imports: [
    NebularModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    DragDropModule,
    InfiniteScrollModule,
    ColorPickerModule,
    InfiniteScrollModule,
    QuillModule.forRoot(),
    NbPopoverModule,
  ],
  declarations: [
    SearchComponent,
    CommonTableComponent,
    PageHeaderComponent,
    PaginationComponent,
    HeaderComponent,
    DynamicFormComponent,
    DynamicFormQuestionsComponent,
    SideNavComponent,
    CommonTableLayoutComponent,
    WeekTimePickerComponent,
    CommonFormComponent,
    AccordianHeaderComponent,
    CommonTableListComponent,
    NameSeparatePipe,
    QuillEditorComponent,
    PhoneInputComponent,
    MultiSelectComponent,
    TimeFormatPipe,
  ],
  exports: [
    NebularModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SearchComponent,
    CommonTableComponent,
    PageHeaderComponent,
    PaginationComponent,
    HeaderComponent,
    DynamicFormComponent,
    DynamicFormQuestionsComponent,
    RouterModule,
    SideNavComponent,
    CommonTableLayoutComponent,
    WeekTimePickerComponent,
    CommonFormComponent,
    AccordianHeaderComponent,
    DragDropModule,
    InfiniteScrollModule,
    CommonTableListComponent,
    NameSeparatePipe,
    ColorPickerModule,
    QuillModule,
    QuillEditorComponent,
    PhoneInputComponent,
    MultiSelectComponent,
    TimeFormatPipe,
  ],
  providers: [FormControlService, FileUploadService, CountriesService, ExcelService],
})
export class SharedModule {}
