import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BookingType } from '../../utils/model/bookingData';

@Component({
  selector: 'side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss'],
})
export class SideNavComponent implements OnInit {
  @Input() options: {
    title;
    templateRef;
  };
  selectedType = 'phone';
  bookingType = 'walkin';
  @Input() isShowButtonsIcons = false;
  @Input() isShowWalkin = false;
  @Input() width;
  @Output() closeNavEmitter = new EventEmitter();
  @Output() selectedTypeChange = new EventEmitter();
  constructor() {}

  ngOnInit(): void {}

  closeNav() {
    this.closeNavEmitter.emit();
  }

  onTypeChange(event, type) {
    if (event) {
      this.selectedTypeChange.emit(type);
    }
  }

  onBookingTypeChange(event, type) {
    if (event) {
      if (type === 'new-booking') {
        this.selectedType = BookingType.PHONE;
        this.isShowButtonsIcons = true;
        this.isShowWalkin = false;
        this.selectedTypeChange.emit(BookingType.PHONE);
      } else if (type === BookingType.WALKIN) {
        this.isShowButtonsIcons = false;
        this.selectedTypeChange.emit(BookingType.WALKIN);
      }
    }
  }
}
