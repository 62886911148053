import { NgModule } from '@angular/core';
import {
  NbAccordionModule,
  NbButtonModule,
  NbCheckboxModule,
  NbContextMenuModule,
  NbFormFieldModule,
  NbIconModule,
  NbInputModule,
  NbLayoutModule,
  NbListModule,
  NbMenuModule,
  NbSpinnerModule,
  NbSelectModule,
  NbTabsetModule,
  NbToastrModule,
  NbToggleModule,
  NbTooltipModule,
  NbButtonGroupModule,
  NbSidebarModule,
  NbCardModule,
  NbDialogModule,
  NbAutocompleteModule,
  NbRadioModule,
  NbDatepickerModule,
  NbTimepickerModule,
  NbTagModule,
  NbCalendarModule,
  NbAlertModule,
} from '@nebular/theme';

@NgModule({
  imports: [
    NbInputModule,
    NbLayoutModule,
    NbButtonModule,
    NbIconModule,
    NbTooltipModule,
    NbCheckboxModule,
    NbFormFieldModule,
    NbSelectModule,
    NbToggleModule,
    NbContextMenuModule,
    NbMenuModule,
    NbTabsetModule,
    NbAccordionModule,
    NbListModule,
    NbCardModule,
    NbSidebarModule.forRoot(),
    NbToastrModule.forRoot(),
    NbSpinnerModule,
    NbAutocompleteModule,
    NbCardModule,
    NbRadioModule,
    NbDatepickerModule.forRoot(),
    NbTimepickerModule.forRoot(),
    NbDialogModule.forChild(),
    NbButtonGroupModule,
    NbDatepickerModule.forRoot(),
    NbTagModule,
    NbCalendarModule,
    NbAlertModule,
  ],
  exports: [
    NbInputModule,
    NbLayoutModule,
    NbButtonModule,
    NbIconModule,
    NbTooltipModule,
    NbCheckboxModule,
    NbFormFieldModule,
    NbSelectModule,
    NbToggleModule,
    NbContextMenuModule,
    NbToastrModule,
    NbTabsetModule,
    NbAccordionModule,
    NbSpinnerModule,
    NbListModule,
    NbAutocompleteModule,
    NbCardModule,
    NbButtonGroupModule,
    NbSidebarModule,
    NbDialogModule,
    NbRadioModule,
    NbDatepickerModule,
    NbTimepickerModule,
    NbTagModule,
    NbCalendarModule,
    NbAlertModule,
  ],
})
export class NebularModule {}
