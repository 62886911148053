import { Component, Input, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  @Input() moduleType;
  constructor(public authService: AuthService) {}

  ngOnInit(): void {}
  closeMenu() {
    document.getElementById('rotate-menu').classList.remove('openmenu');
    document.getElementById('header').classList.remove('header');
    document.getElementById('body').classList.remove('body-scroll');
  }
}
