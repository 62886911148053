export interface BookingData {
  timeSlot: any;
  customerId: string;
  bookingDateTime: string | Date;
  people: number;
  timezone: string;
  readyToShareTable: boolean;
  duration: string;
  exitDateTime: string | Date;
  serviceId: string;
  statusId: string;
  bookingNote: string;
  customerNote: string;
  managerNote: string;
  sectionIds: Array<string>;
  tables?: Array<TableData>;
  payment?: PaymentData;
  badges?: Array<Badge>;
  guestList?: Array<Guest>;
  bookingType: BookingType;
  bookingOptions?: Array<BookingOption>;
  bookingFor: string;
}

export interface TableData {
  tableId: string;
  sectionId: string;
  seated?: number;
}
export interface BookingOption {
  name: string;
  bookingOptionId: string;
  quantity: number;
  childBookingOption?: Array<ChildBookingOption>;
  bookingType: string;
  pricePerPerson: 0;
  totalPrice: number;
  bookingList: Array<any>;
  quantityPerPerson: Array<any>;
  applyForAll: boolean;
}
export interface ChildBookingOption {
  name: string;
  bookingOptionId: string;
  quantity: number;
  bookingType: string;
  pricePerPerson: 0;
  quantityPerPerson: Array<any>;
}
export interface Badge {
  badgeId: string;
  description: string;
}
export interface Guest {
  name: string;
  contactNumber: string;
}
export interface PaymentData {
  type: PaymentType;
  amount: string;
}
export enum PaymentType {
  NOPAYMENT = 'no-payment',
  DEPOSITE = 'deposit',
  FULLPAYMENT = 'full-payment',
  PREAUTH = 'preauth',
  BOOKINGOPTION = 'booking-option',
}
export enum BookingType {
  WIDGET = 'widget',
  WALKIN = 'walkin',
  PHONE = 'phone',
  MESSAGE = 'message',
}
