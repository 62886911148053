<table class="common-table-main-container">
  <thead>
    <tr class="table-header">
      <th *ngIf="tableStructure.isCheckBox" class="table-column-cell pl-30">
        <nb-checkbox status="basic"></nb-checkbox>
      </th>
      <th [ngClass]="{ 'pl-30': !tableStructure.isCheckBox }" *ngFor="let column of tableStructure.columns">
        {{ column.name }}
      </th>
      <th *ngIf="tableStructure.actions.length > 0">Action</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let row of tableData; let rIndex = index" height="40px">
      <td *ngIf="tableStructure.isCheckBox" class="table-column-cell pl-30">
        <nb-checkbox status="basic"></nb-checkbox>
      </td>
      <td
        [ngClass]="{ 'pl-30': !tableStructure.isCheckBox }"
        class="cursor-pointer"
        *ngFor="let cell of tableStructure.columns"
        nbTooltip="Click to edit"
        (click)="onActionClick($event, 'edit', rIndex, row._id)"
      >
        <span *ngIf="cell.type === 'text'">{{ row[cell.value] !== undefined ? row[cell.value] : "N/A" }}</span>
        <span *ngIf="cell.type === 'date'">{{ row[cell.value] !== undefined ? (row[cell.value] | date: "dd-MMM-yyyy") : "N/A" }}</span>
        <span *ngIf="cell.type === 'active'">
          <span class="ml-10">
            <nb-icon *ngIf="row[cell.value]" icon="checkmark-circle-2-outline" [options]="{ color: '#34A853' }"></nb-icon>
            <nb-icon *ngIf="!row[cell.value]" icon="close-circle-outline" [options]="{ color: '#FE5050' }"></nb-icon>
          </span>
        </span>
      </td>
      <td *ngIf="tableStructure.actions.length" class="table-column-cell pr-30">
        <div class="d-flex">
          <nb-icon
            class="cursor-pointer"
            *ngFor="let action of tableStructure.actions"
            [icon]="action.icon"
            (click)="onActionClick($event, action.type, rIndex, row._id)"
            [options]="{ color: '#66737F', height: '20px', width: '20px' }"
          ></nb-icon>
        </div>
      </td>
    </tr>
  </tbody>
</table>
