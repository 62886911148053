<div class="page-header-main">
  <div class="page-header">{{ title }}</div>
  <div class="d-flex align-center">
    <search *ngIf="isShowSearchBar" [placeholder]="placeholder" (keyup)="onSearch($event)"></search>
    <button class="ml-10" nbButton status="primary" nbTooltip="Download CSV" (click)="onDownload()">
      <nb-icon icon="cloud-download-outline" [options]="{ color: 'white' }"></nb-icon>
    </button>
    <button
      id="add_btn"
      class="ml-10"
      nbButton
      status="primary"
      nbTooltip="Add new account"
      (click)="onAdd($event)"
      *ngIf="writePermission"
    >
      <nb-icon icon="plus-circle-outline" [options]="{ color: 'white' }"></nb-icon>
    </button>
  </div>
</div>
