<nb-form-field>
  <nb-icon nbPrefix icon="search-outline" [options]="{ color: '#B8C1CC' }"></nb-icon>
  <input
    type="text"
    nbInput
    placeholder="Search Here"
    status="basic"
    (keyup)="doSearch($event)"
    [(ngModel)]="search"
    [placeholder]="placeholder"
  />
</nb-form-field>
