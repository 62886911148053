export class FormValidations {
  urlRegex = new RegExp("^(?:http(s)?:\\/\\/)?[\\w.-]+(?:\\.[\\w\\.-]+)+[\\w\\-\\._~:/?#[\\]@!\\$&'\\(\\)\\*\\+,;=.]+$");
  checkInvalidForm(form, questions) {
    for (let i = 0; i < questions.length; i++) {
      let question = questions[i];
      if (question.key && form.controls[question.key].invalid) {
        form.controls[question.key].setErrors({ invalid: true });
        return 'Please enter valid value for ' + question.label;
      }
    }
  }
  checkValidations(form, questions) {
    for (let i = 0; i < questions.length; i++) {
      let question = questions[i];
      if (question.key && form.controls[question.key].valid) {
        if (
          form.controls[question.key].value &&
          form.controls[question.key].value !== '' &&
          !form.controls[question.key].value.trim().length
        ) {
          form.controls[question.key].setErrors({ invalid: true });
          return { error: 'Please enter valid value for ' + question.label, errStatus: true };
        }
        if (form.controls[question.key].value) {
          switch (question.type) {
            case 'url':
              if (!this.isUrlValid(form.controls[question.key].value)) {
                form.controls[question.key].setErrors({ invalid: true });
                return { error: 'Please enter valid url', errStatus: true };
              }
              break;
            case 'phone':
              if (!this.isValidPhone(form.controls[question.key].value)) {
                form.controls[question.key].setErrors({ invalid: true });
                return { error: 'Please enter valid phone number', errStatus: true };
              }
              break;
          }
        }
      }
    }
  }
  isUrlValid(userInput) {
    let regexQuery = "^(?:http(s)?:\\/\\/)?[\\w.-]+(?:\\.[\\w\\.-]+)+[\\w\\-\\._~:/?#[\\]@!\\$&'\\(\\)\\*\\+,;=.]+$";
    let url = new RegExp(regexQuery);
    return url.test(userInput);
  }
  isValidPhone(userInput) {
    if (userInput) {
      if (userInput.length <= 10 && userInput.length > 6) {
        let regex = /^[0-9]*$/;
        return regex.test(userInput);
      } else return false;
    } else return false;
  }
  restrictNumber(event) {
    const pattern = /[0-9\+\-\.\ ]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (!pattern.test(inputChar)) {
      event.preventDefault();
    }
  }
  restrictWithDecimalNumber(event) {
    const pattern = /[0-9]/;
    const inputChar = String.fromCharCode(event.charCode);
    const value = event.target.value;
    if (event.keyCode === 46 && (value.toString().includes(".") || value.trim() === "")) {
      event.preventDefault();
    } else if (event.keyCode === 46) {
      return;
    }
    if (event.keyCode !== 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }
}
