import { Component, OnInit } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NotificationService, NotificationStatus } from '../../services/notification.service';
import { AuthService } from '../../services/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
  animations: [
    trigger('slideUpAnimation', [
      // route 'enter' transition
      transition(':enter', [
        // css styles at start of transition
        style({ transform: 'translateY(100%)' }),

        // animation and styles at end of transition
        animate(
          '3s ease-in-out',
          style({
            transform: 'translateY(0)',
          })
        ),
      ]),
    ]),
  ],
})
export class ForgotPasswordComponent implements OnInit {
  forgotPassForm;
  constructor(private _notificationService: NotificationService, private _authService: AuthService, private router: Router) {}

  ngOnInit(): void {
    this.forgotPassForm = new FormGroup({
      email: new FormControl('', [Validators.email, Validators.required]),
    });
  }
  onForgotPassword() {
    if (this.forgotPassForm.valid) {
      if (!this.forgotPassForm.controls['email'].value.trim().length) {
        this._notificationService.showNotification(NotificationStatus.ERROR, 'Please enter valid email address.');
        this.forgotPassForm.controls['email'].setErrors({ incorrect: true });
        return;
      }
      this._authService
        .forgotPassword(this.forgotPassForm.value)
        .then((res: any) => {
          this.router.navigate(['login']);
          this._notificationService.showNotification(NotificationStatus.SUCCESS, res.message);
        })
        .catch((err) => {
          console.log(err);
          this._notificationService.showNotification(
            NotificationStatus.ERROR,
            err.error.message ? err.error.message : 'Something went wrong'
          );
        });
    } else {
      if (this.forgotPassForm.controls['email'].invalid) {
        this._notificationService.showNotification(NotificationStatus.ERROR, 'Please enter a valid email address.');
      }
    }
  }

  goToLogin() {
    this.router.navigate(['login']);
  }
}
