import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Urls } from '../utils/urls';
import { Subject } from 'rxjs';

@Injectable()
export class FcmService {
  emailsTemplatesDetailSaveChanged = new Subject();

  constructor(private http: HttpClient) {}

  addfcm(userId, token) {
    return new Promise((resolve, reject) => {
      this.http.put(Urls.addfcmtoken + '/' + userId, token).subscribe(
        (res) => {
          resolve(res);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  getAllNotification(userId, status): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.get(Urls.allnotification + '/' + userId + '?type=' + status + '&all=true').subscribe(
        (res) => {
          resolve(res);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  updateStatusNotification(userId, bookingId, status) {
    return new Promise((resolve, reject) => {
      this.http.put(Urls.allnotification + '/' + userId + '/' + bookingId, status).subscribe(
        (res) => {
          resolve(res);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }
}
