import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { FormBase, FormControlTypes } from '../../../utils/helper/FormBase';
import { FormGroup } from '@angular/forms';
import { Observable, of, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { NotificationService, NotificationStatus } from '../../../services/notification.service';
import { FileUploadService } from '../../../services/file-upload.service';

@Component({
  selector: 'dynamic-form-questions',
  templateUrl: './dynamic-form-questions.component.html',
  styleUrls: ['./dynamic-form-questions.component.scss'],
})
export class DynamicFormQuestionsComponent implements OnInit, OnDestroy {
  @Input() question: FormBase;
  @Input() questionList: FormBase[];
  @Input() form: FormGroup;
  @ViewChild('autoInput') input;
  @ViewChild('states') state;
  filteredOptions$: Observable<string[]>;
  filteredStates$: Observable<string[]>;
  stateValue;
  isFileLoading = false;
  fileUrl;
  private unsubscribe: Subject<void> = new Subject();
  get isValid() {
    return this.form.controls[this.question.key].valid;
  }
  constructor(private _notificationService: NotificationService, private _fileUploadService: FileUploadService) {}

  ngOnInit(): void {
    if (this.question.controlType === FormControlTypes.AUTOCOMPLETE) {
      this.filteredOptions$ = of(this.question.options);
    }
    this._fileUploadService.fileLoading.pipe(takeUntil(this.unsubscribe)).subscribe((data) => {
      this.isFileLoading = data;
    });
    this._fileUploadService.fileUrl.pipe(takeUntil(this.unsubscribe)).subscribe((data) => {
      this.fileUrl = data;
    });
  }
  private filter(value: any): string[] {
    let filterValue;
    if (typeof value === 'object') {
      filterValue = value.name.toLowerCase();
    } else {
      filterValue = value.toLowerCase();
    }
    // @ts-ignore
    return this.question.options.filter((optionValue) => optionValue.name.toLowerCase().includes(filterValue));
  }
  onChange() {
    this.filteredOptions$ = this.getFilteredOptions(this.input.nativeElement.value);
  }
  getFilteredOptions(value: string): Observable<string[]> {
    return of(value).pipe(map((filterString) => this.filter(filterString)));
  }
  onSelectionChange($event) {
    this.filteredOptions$ = this.getFilteredOptions($event);
    this.questionList.find((ques) => ques.key === this.question.relatedValue).options = $event.states;
    this.filteredStates$ = of($event.states);
  }
  private filterState(value: any): string[] {
    let filterValue;
    filterValue = value.toLowerCase();
    return this.question.options
      ? this.question.options.filter((optionValue) => {
          if (typeof optionValue === 'object') {
            return optionValue.name.toLowerCase().includes(filterValue);
          } else {
            return optionValue.toLowerCase().includes(filterValue);
          }
        })
      : [];
  }
  onStateChange() {
    this.filteredStates$ = this.getFilteredStates(this.state.nativeElement.value);
  }
  getFilteredStates(value: string): Observable<string[]> {
    return of(value).pipe(map((filterString) => this.filterState(filterString)));
  }
  onStateSelectionChange($event) {
    this.filteredStates$ = this.getFilteredStates($event);
  }

  viewHandle(value: any) {
    if (typeof value === 'object') {
      return value.name;
    } else {
      return value;
    }
  }
  restrictNumber(event) {
    const pattern = /[0-9\+\-\ ]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (!pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  fileUpload(event) {
    let fileName;
    let fileType;
    if (event.target && event.target.files.length) {
      fileType = event.target.files[0].type.substr(event.target.files[0].type.lastIndexOf('/') + 1, event.target.files[0].type.length);
      if (fileType !== 'jpeg' && fileType !== 'png') {
        this._notificationService.showNotification(NotificationStatus.ERROR, "Only 'jpeg' and 'png' type files are allowed");
        return;
      } else {
        this._fileUploadService.fileChanged.next({ type: fileType, file: event.target.files[0] });
      }
    }
  }
  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
