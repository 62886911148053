import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'name_separate',
})
export class NameSeparatePipe implements PipeTransform {
  transform(value: any, ...args: any[]): any {
    if (value) {
      let name = value.split('_').toString();
      return name.replace(/,/g, ' ');
    }
  }
}
